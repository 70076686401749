
/* Breadcrumb -----------------------------------------*/

#breadcrumb {
    font-size: 0.8em;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 10px;
    ul {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        * {
            display: contents;
        }
        li {
            &:not(:first-child):before {
                content: "\f0da";
                color: #aaa;
                margin: 0 0.6rem;
                position: relative;
                top: 1px;
                font-family: "FontAwesome";
            }
        }
    }

    .breadcrumb-title {
        color: #999;
    }

    & + .main-big-title {
        margin-top: -15px;
        @include mq-down(lg) {
            margin-top: -10px;
        }

    }

    @include mq-down(lg) {
        padding: 5px 10px;
        font-size: 0.7em;
    }
}

/* embed -----------------------------------------*/

.youtube-container,
.video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    .rll-youtube-player {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
  }

.wp-has-aspect-ratio{
    .youtube-container,
    .video-container {
        display: contents;
    }
}

.rll-youtube-player > div:not([class]) {
    position: initial;
}

  /* design -----------------------------------------*/

  .hide{display:none;}

  a.wrap_link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

  /* menu -----------------------------------------*/

  ul.menu li a{
    text-decoration: none;
  }