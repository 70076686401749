// Bass ========================================

h1,
h2,
h3,
h4,
h5 {
  line-height: 2;
  display: block;
}

h1 {
  font-size: 1.6em;
  line-height: 1.5;
  font-weight: 600;
}
.content h2,
h2 {
  position: relative;
  padding: 0.5em 1em;
  font-size: 1.4em;
  margin: 1.5em 0 1em;
  line-height: 1.5;
}

.content h3,
h3 {
  position: relative;
  padding: 0.3em 1em;
  font-weight: bold;
  margin: 1.2em 0 0.8em;
  font-size: 1.2em;
}

.content h4,
h4 {
  position: relative;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.2em 1em;
  margin: 0.8em 0 0.6em;
}

.content h5,
h5 {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0.6em 0 0.8em;
}
h5 span {
  font-weight: bold;
}

p,
pre {
  // line-height: 1.6;
  margin-bottom: 1em;
}

pre {
  background-color: #000;
  color: #fff;
  padding: 1em;
  overflow: auto;
}
// dl,
// menu,
// ol,
// ul {
//   margin: 0 0 $content-line-space;
// }
// li{
//   margin: 6px 0;
// }
// nav{
//   ul,li{
//     margin: 0;
//   }
// }

dl {
  width: 100%;
  overflow: hidden;
}

dt {
  clear: both;
  padding: 5px;
  font-weight: bold;
}

dd {
  color: #878685;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

// dd {
//   margin: 0;
// }

blockquote {
  position: relative;
  border-radius: 5px;
  padding: 1.5em;
  margin: 0 0 1.5em 0;
  background: #f9f9f9;
  color: #333;
  box-shadow: 0 0 0px 1px rgb(0 0 0 / 10%);
  &:before,
  &:after {
    position: absolute;
    font-family: $font-awesome;
    font-size: 24px;
    line-height: 24px;
    color: currentColor;
    opacity: 0.2;
  }
  &:before{
    top: 10px;
    left: 10px;
    content: "\f10d";
  }
  &:after{
    bottom: 10px;
    right: 10px;
    content: "\f10e";
  }
  .wp-embedded-content {
    padding: 0.8em 2em;
    &:before {
      content: "";
    }
  }
  & > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .blockquote_ref {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    opacity: 0.6;
    font-size: 0.8em;
    margin-bottom: -1em;
    &::before {
      border-top: 1px solid;
      content: "";
      margin: 0.8em 1.2em 0.8em 0;
      min-width: 50px;
      max-width: 80px;
      flex: 1;
      display: inline-block;
      height: 1px;
      background: #999;
    }
    a {
      text-decoration: none;
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
}
th,
td {
  border: 1px solid #ccc;
  padding: 8px 12px;
}

table {
  width: 100%;

  thead{
    th {
      background: #eee;
    }
  }

  tbody {
    th {
      width: 25%;
      background-color: #f9f9f9;
    }
  }
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    margin: 0 2px;
    background-color: #ccc;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #666;
  }
}

// Misc ========================================

#logo {
  margin: 0;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  // max-width: 100%;
  height: auto;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.alignright {
  float: right;
  margin-left: 12px;
  margin-bottom: 12px;
}
.alignleft {
  float: left;
  margin-right: 12px;
  margin-bottom: 12px;
}
.alignfull {
  margin: 0 -40px;
  max-width: none;
  width: calc(100% + 80px);
}

.wp-caption {
  max-width: 100% !important;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f3f3f3;
  text-align: center;
  width: fit-content;
  padding: 10px;
  img {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
  }
  p.wp-caption-text {
    font-size: 80%;
    margin: 0;
    margin-top: 10px;
  }
}

.gallery{
  display: flex;
  flex-wrap: wrap;
}

.gallery-item {
  margin: 0;
  img {
    margin: 0;
    vertical-align: middle;
    border: none;
    width: 100%;
  }
}

dl.gallery-item {
  dt {
    border: none;
    margin-bottom: 0;
    padding: 1px;
  }
  dd.gallery-caption {
    border: none;
    padding: 0;
  }
}

@for $i from 1 through 9 {
  .gallery-columns-#{$i} .gallery-item{
    width: 100% / $i;
    padding: 2px;
  }
}

@media screen and (max-width: 640px) {
	.gallery-columns-3 .gallery-item,
  .gallery-columns-4 .gallery-item {
		width: 50%;
	}
	.gallery-columns-5 .gallery-item {
		width: 33.3333%;
	}
}