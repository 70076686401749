@import "../module/settings";

.diver_af_ranking_wrap {
    margin-bottom: 1.5em;
    &.full .rank_desc_wrap {
        flex-direction: column;
        .rank_img {
            width: 100%;
        }
    }
    &.right .rank_desc_wrap {
        @include mq-up(md){
            flex-direction: row-reverse;
        }
    }

    .rank_rem {
        background-color: #f9f9f9;
        padding: 0.8em 1em;
        margin-bottom: 1em;
    }
}

// .content .rank_title::before,
// .content .rank_title::after {
//     content: "";
//     position: relative;
//     width: 0;
//     height: 0;
//     border: none;
//     transform: none;
//     background: none;
// }

.rank_h {
    border: none;
    position: relative;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    &::before {
        content: " ";
        background-repeat: no-repeat;
        display: block;
        height: 65px;
        width: 65px;
        background-size: 100%;
        line-height: 65px;
        font-size: 20px;
        background-image: url(../images/rank/diver_rank_icon.png);
        font-weight: bold;
        text-align: center;
        text-shadow: 0.5px 0.5px 0 #fff, -0.5px 0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px -0.5px 0 #fff;
        color: #e8453b;
        @include mq-down(md) {
            height: 45px;
            width: 45px;
            line-height: 45px;
        }
    }

    .rank_title_wrap {
        flex: 1;
    }
    .rank_title {
        font-size: 1.3em;
        font-weight: bold;
        padding: 0;
        margin: 0;
        line-height: 1.2;
        box-shadow: none;
        border-radius: 0;
        border: none;
        background: none;
        color: inherit;
        @include mq-down(md) {
            font-size: 1.2em;
        }
        p:empty{
            display: none;
        }
    }
    .review_star {
        position: relative;
        left: 0;
        bottom: 0px;
        margin: 0;
        display: block;
        margin-top: 8px;
    }
}

@for $i from 0 through 8 {
    .rank_h.\3#{$i}:before {
        background-image: url("../images/rank/diver_rank_#{$i}.png");
    }
    .rank_h.\31\3#{$i}:before {
        content: "10";
    }
}

.rank_h.\30:before {
    content: "0";
}
.rank_h.\39:before {
    content: "9";
}
.rank_h.\32\30:before {
    content: "20";
}

.content .rank_title p {
    padding: 0;
}

.rank_desc_wrap {
    display: flex;
    gap: 1em;
    margin-bottom: 1em;
    .rank_img {
        width: 35%;
        text-align: center;
        img {
            margin: 0 auto;
            max-width: 100%;
        }
    }
    .rank_desc {
        flex: 1;
        .rank_minih {
            line-height: 1.2;
            padding: .6em .8em;
            font-weight: bold;
            background: #f6cb47;
            color: #fff;
            border-radius: 3px;
            position: relative;
            margin-bottom: 12px;
            &.no-bg{
                padding: 0;
            }
            &.is-style-border{
                background-color: transparent;
                border-bottom: 2px solid #000;
                color: #000;
                padding: 0 0 .6em;
                border-radius: 0;
            }
            &.is-style-black{
                background-color: transparent;
                color: #000;
            }

        }
        .desc {
            font-size: 0.9em;
        }
    }
    @include mq-down(md) {
        flex-direction: column;
        .rank_img {
            width: 100%;
        }
    }
}

.rank_btn_wrap {
    display: flex;
    
    .rank_buy_link,
    .rank_more_link {
        flex: 1;
        margin: 5px;
        a {
            position: relative;
            color: #ffffff;
            padding: 0.8em 1.2em;
            background: #2bc136;
            border-radius: 3px;
            display: inline-block;
            width: 100%;
            text-align: center;
            text-decoration: none;
            font-weight: bold;
            &:after {
                content: "\f138";
                padding-left: 10px;
                font-family: fontAwesome;
            }
        }
    }

    .rank_more_link a {
        background: #f55151;
    }

    @include mq-down(md) {
        a {
            padding: 1em 0.5em;
            font-size: 0.8em;
        }
    }
}
